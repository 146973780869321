// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CommonLanding from '../../../components/CommonLanding'
import pageTitle from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'
import messages from '../../../components/CommonLanding/messages/UxUiAudit/UxUiAudit.lang'
import SEO from '../../../components/seo'

/* eslint-disable max-len */
const heroBg = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/hero-bg.jpeg" alt="" objectFit="cover" className="imageCover" />
)
const customAnalysis01 = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/custom-analysis-01.jpeg" alt="" objectFit="contain" className="image" />
)
const customAnalysis02 = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/custom-analysis-02.jpeg" alt="" objectFit="contain" className="image" />
)
const customAnalysis03 = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/custom-analysis-03.jpeg" alt="" objectFit="contain" className="image" />
)
const customAnalysis04 = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/custom-analysis-04.jpeg" alt="" objectFit="contain" className="image" />
)
const expertDominic = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/expert-dominic.png" alt="" objectFit="contain" className="image" />
)
const expertDominik = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/expert-dominik.png" alt="" objectFit="contain" className="image" />
)
const expertPatrick = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/expert-patrick.png" alt="" objectFit="contain" className="image" />
)
const expertTobias = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/expert-tobias.png" alt="" objectFit="contain" className="image" />
)
const faqWoman = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/faq-woman.jpeg" alt="" objectFit="contain" className="image" />
)
const moreInoBg = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/more-information-bg.jpeg" alt="" objectFit="cover" className="imageCover" />
)
const moreInoImg = (
  <StaticImage src="../../../images/common-landing/ux-ui-audit/more-information-img.png" alt="" objectFit="contain" className="image" />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const data = [
  {
    type: 'ScrollSpy',
    data: [
      {
        icon: 'icon-info',
        title: messages.scrollSpy1,
        id: 'intro'
      },
      {
        icon: 'icon-file-board',
        title: messages.scrollSpy2,
        id: 'benefits'
      },
      {
        icon: 'icon-faq',
        title: messages.scrollSpy3,
        id: 'faq'
      },
      {
        icon: 'icon-processes',
        title: messages.scrollSpy4,
        id: 'processes'
      },
      {
        icon: 'icon-devices1',
        title: messages.scrollSpy5,
        id: 'plans'
      },
      {
        icon: 'icon-team',
        title: messages.scrollSpy6,
        id: 'team'
      },
      {
        icon: 'icon-phone',
        title: messages.scrollSpy7,
        id: 'contact'
      }
    ]
  },
  {
    type: 'HeroTeaser',
    modeSettings: {
      mode: 'dark',
      bgImg: heroBg,
      bgColour: 'white',
      overlay: 'dark'
    },
    title: messages.heroTitle,
    titleTag: 'h1',
    text: messages.heroText,
    buttons: [
      {
        label: messages.heroExplore,
        anchor: 'plans',
        btnConf: {
          color: 'accent',
          iconRight: 'icon-arrow_03-down-medium'
        }
      },
      {
        label: messages.heroDiscover,
        anchor: 'processes',
        btnConf: {
          color: 'white',
          outline: true,
          iconRight: 'icon-arrow_03-down-medium'
        }
      }
    ],
    withContactForm: true,
    contactFormConf: {
      formHeadline: messages.formTitle,
      formButtonLabel: messages.formButtonLabel,
      formImg: moreInoImg
    }
  },
  {
    type: 'Section',
    id: 'intro',
    title: messages.customAnalysisTitle,
    text: messages.customAnalysisText,
    buttonLink: '/solutions/ux-ui-audit/#contact',
    buttonLabel: messages.customAnalysisBtn,
    galleryAlignment: 'right',
    gallery: [
      {
        img: customAnalysis01
      },
      {
        img: customAnalysis02
      },
      {
        img: customAnalysis03
      },
      {
        img: customAnalysis04
      },
    ],
  },
  {
    type: 'Section',
    id: 'benefits',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    layoutConf: {
      colsEmpty: true,
      hasHeader: true
    },
    title: messages.optimizeSectionTitle,
    tiles: {
      count: 3,
      data: [
        {
          icon: 'icon-compass1',
          title: messages.optimizeSectionGuidanceTitle,
          text: messages.optimizeSectionGuidanceText
        },
        {
          icon: 'icon-send',
          title: messages.optimizeSectionConversionTitle,
          text: messages.optimizeSectionConversionText
        },
        {
          icon: 'icon-heart',
          title: messages.optimizeSectionLoyaltyTitle,
          text: messages.optimizeSectionLoyaltyText
        },
        {
          icon: 'icon-user1',
          title: messages.optimizeSectionAnalysisTitle,
          text: messages.optimizeSectionAnalysisText
        },
        {
          icon: 'icon-upload1',
          title: messages.optimizeSectionActingTitle,
          text: messages.optimizeSectionActingText
        },
        {
          icon: 'icon-medal',
          title: messages.optimizeSectionIndustryTitle,
          text: messages.optimizeSectionIndustryText
        },
      ]
    }
  },
  {
    type: 'Section',
    id: 'faq',
    layoutConf: {
      hasHeader: true,
      headerCentered: true,
      colsTop: true
    },
    title: messages.faqSectionTitle,
    text: messages.faqSectionText,
    galleryAlignment: 'left',
    gallery: [
      {
        img: faqWoman,
        clickable: false
      }
    ],
    faq: [
      {
        question: messages.faqSectionQ1,
        answer: messages.faqSectionA1
      },
      {
        question: messages.faqSectionQ2,
        answer: messages.faqSectionA2
      },
      {
        question: messages.faqSectionQ3,
        answer: messages.faqSectionA3
      },
      {
        question: messages.faqSectionQ4,
        answer: messages.faqSectionA4
      },
      {
        question: messages.faqSectionQ5,
        answer: messages.faqSectionA5
      },
    ]
  },
  {
    type: 'Section',
    id: 'processes',
    modeSettings: {
      mode: 'dark',
      bgImg: moreInoBg,
      bgColour: 'white',
      overlay: 'coloured'
    },
    layoutConf: {
      gallerySm: true
    },
    title: messages.moreInfoSectionTitle,
    text: messages.moreInfoSectionText,
    buttonLink: '/solutions/ux-ui-audit/#contact',
    buttonLabel: messages.moreInfoSectionBtn,
    galleryAlignment: 'left',
    gallery: [
      {
        img: moreInoImg,
        clickable: false,
        plain: true
      },
    ],
  },
  {
    type: 'Section',
    layoutConf: {
      colsEmpty: true,
      hasHeader: true
    },
    title: messages.stepsSectionTitle,
    tiles: {
      count: 3,
      data: [
        {
          icon: 'icon-arrow_03-right-medium',
          preTitle: messages.stepsSectionCallPreTitle,
          title: messages.stepsSectionCallTitle,
          text: messages.stepsSectionCallText
        },
        {
          icon: 'icon-arrow_03-right-medium',
          preTitle: messages.stepsSectionAuditPreTitle,
          title: messages.stepsSectionAuditTitle,
          text: messages.stepsSectionAuditText
        },
        {
          icon: 'icon-arrow_03-right-medium',
          preTitle: messages.stepsSectionImplPreTitle,
          title: messages.stepsSectionImplTitle,
          text: messages.stepsSectionImplText
        }
      ]
    }
  },
  {
    type: 'Section',
    id: 'plans',
    layoutConf: {
      colsEmpty: true,
      headerCentered: true,
      hasHeader: true
    },
    title: messages.plansSectionTitle,
    offerTiles: {
      count: 3,
      data: [
        {
          title: messages.plansSectionBasicTitle,
          text: messages.plansSectionBasicText,
          list: [
            messages.plansSectionBasicBenefit1,
            messages.plansSectionBasicBenefit2,
            messages.plansSectionBasicBenefit3,
            messages.plansSectionBasicBenefit4,
            messages.plansSectionBasicBenefit5,
            messages.plansSectionBasicBenefit6
          ],
          button: {
            link: '/solutions/ux-ui-audit/#contact',
            label: messages.plansSectionBtn
          }
        },
        {
          title: messages.plansSectionInsightTitle,
          text: messages.plansSectionInsightText,
          accent: true,
          note: {
            icon: 'icon-checklist',
            text: messages.plansSectionInsightPreBenefits
          },
          list: [
            messages.plansSectionInsightBenefit1,
            messages.plansSectionInsightBenefit2,
            messages.plansSectionInsightBenefit3,
            messages.plansSectionInsightBenefit4,
            messages.plansSectionInsightBenefit5,
            messages.plansSectionInsightBenefit6,
            messages.plansSectionInsightBenefit7
          ],
          button: {
            link: '/solutions/ux-ui-audit/#contact',
            conf: {
              outline: false,
              color: 'accent'
            },
            label: messages.plansSectionBtn
          },
          badgeAccent: messages.plansSectionBadge
        },
        {
          title: messages.plansSectionUltimateTitle,
          text: messages.plansSectionUltimateText,
          note: {
            icon: 'icon-checklist',
            text: messages.plansSectionUltimatePreBenefits
          },
          list: [
            messages.plansSectionUltimateBenefit1,
            messages.plansSectionUltimateBenefit2,
            messages.plansSectionUltimateBenefit3,
            messages.plansSectionUltimateBenefit4,
            messages.plansSectionUltimateBenefit5,
            messages.plansSectionUltimateBenefit6,
            messages.plansSectionUltimateBenefit7
          ],
          button: {
            link: '/solutions/ux-ui-audit/#contact',
            label: messages.plansSectionBtn
          }
        }
      ]
    },
  },
  {
    type: 'Section',
    id: 'team',
    layoutConf: {
      colsEmpty: true
    },
    persons: {
      titleConf: {
        title: messages.expertsSectionTitle,
        centered: true
      },
      data: [
        {
          img: expertDominic,
          name: 'Dominic Merfert',
          position: 'Senior UX & Process Engineer',
          text: messages.expertsSectionDominicText
        },
        {
          img: expertTobias,
          name: 'Tobias Heinrich',
          position: 'Senior UX/UI Designer',
          text: messages.expertsSectionTobiasText
        },
        {
          img: expertPatrick,
          name: 'Patrick Pauluhn',
          position: 'UX/UI Lead',
          text: messages.expertsSectionPatrickText
        },
        {
          img: expertDominik,
          name: 'Dominik Köhler',
          position: 'Senior UX/UI Engineer',
          text: messages.expertsSectionDominikText
        }
      ]
    }
  },
  {
    type: 'HeroTeaser',
    id: 'contact',
    title: messages.consultationSectionTitle,
    titleTag: 'div',
    text: messages.consultationSectionText,
    withContactForm: true,
    contactFormConf: {
      formHeadline: messages.formTitle,
      formButtonLabel: messages.formButtonLabel,
      formImg: moreInoImg
    }
  }
]

const UxUiAudit = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer modern />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(pageTitle.TitleUxUi)}
        description={formatMessage(metaDescription.UxUiPageDescription)}
        lang={locale}
      />
      <CommonLanding data={data} />
    </Layout>
  )
}

export default applyStylingHoc({})<$Diff<Props, StyleProps> & StyleOuterProps, Function>(UxUiAudit)
